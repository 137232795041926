import React, { useState } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"
import Logo from "../components/logo"
import Header from "../components/header"
import ImportantMessage from "../components/important-msg"

const formSchema = Yup.object().shape({
    _replyto: Yup.string()
      .email("Invalid email")
      .required("Required"),
    fullname: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    inquiryType: Yup.array(),
    resellerCompany: Yup.string(),
    resellerLocation: Yup.string(),
    _cc: Yup.string(),
    message: Yup.string().required("Required")
  });

const FormInfo = () => {
  return (
    <div>
      <Header headerText="About us" headingTag="h3" classTag="h5" />
        <p>Independent Forensics (IFI) develops new innovative and reliable products and services for crime laboratories, forensic genetics, and human identification.</p>
        <ul>
            <li><a href="tel:1-708-234-1200">1.708.234.1200</a> | <a href="tel:1-866-434-2400">1.866.434.2400</a></li>
            <li>Mobile Collections Available!</li>
            <li><a href="https://www.google.com/maps/place/Independent+Forensics/@41.8518446,-88.0293994,17z/data=!3m1!4b1!4m5!3m4!1s0x880e5268c7ed5a55:0xf4bc367e4bd3876f!8m2!3d41.8518446!4d-88.0272107" rel="noopener noreferrer" target="_blank" >500 Waters Edge Lane Suite 210, Lombard IL 60148</a></li>
            <li>info@ifi-test.com</li>
            <li>M-F: 9 am - 5 pm CST | Sat: By Appointment Only | Sun: Closed</li>
            <li>Holiday hours in effect on Thanksgiving, Christmas eve/day and New Years eve/day. Please call in advance</li>
            <li>Spanish Speaking DNA Collection Specialists</li>
            <li><a href="https://g.page/IndependentForensics/review?gm" rel="noopener noreferrer" target="_blank" >Leave a review on google!.</a></li>
            <li>DNA Collections by appointment only.</li>
        </ul>
    </div>
  )
}

export default function GenForm() {

      const cc = "info@ifi-test.com,dina@ifi-test.com,allan@ifi-test.com,justin@ifi-test.com,karl@ifi-test.com"
    
     /* Server State Handling */
     const [serverState, setServerState] = useState();
     const [showForm, setShowForm] = useState(true);
     const [expand, setExpand] = useState(false);

     const handleServerResponse = (ok, msg) => {
       setServerState({ok, msg});
       setShowForm(!showForm)
     };
     const handleOnSubmit = (values, actions) => {
       axios({
         method: "POST",
         url: "https://formspree.io/f/mvornqbv",
         data: values
       })
         .then(response => {
           actions.setSubmitting(false);
           actions.resetForm();
           handleServerResponse(true, "Thanks! Your form has been submitted. Please check your email.");
         })
         .catch(error => {
           actions.setSubmitting(false);
           handleServerResponse(false, error.response.data.error);
         });
     };

     return (
       <section className="xlarge">
              <div className="center-text" style={{paddingBottom: "3rem"}}>
                <Header headerText="Contact Form" />
              </div>
              <div className="flex">
                <div className={["col", "colbgblue", "colflex"].join(' ')}>
                  <Logo />
                <div className={(expand ? "" : "shrink")}>
                <Formik
                initialValues={{ _replyto: "", fullname: "", phone: "", inquiryType: [], resellerCompany: "", resellerLocation: "", _cc: cc, message: "" }}
                onSubmit={handleOnSubmit}
                validationSchema={formSchema}
                >
                    {({ isSubmitting }) => (
                        <Form id="fs-frm" noValidate>
                          <div className={(showForm ? "show" : "hide" )}>
                            <div className="field">
                              <div className="label">
                                <label htmlFor="email">Email:</label>
                              </div>
                              <div className="control">
                                  <Field className="input" id="email" type="email" name="_replyto" />
                              </div>
                              <ErrorMessage name="_replyto" className="errormsg" component="p" />
                            </div>
                            <div className="field">
                                <div className="label">
                                  <label htmlFor="fullname">Full name:</label>
                                </div>
                                <div className="control">
                                    <Field className="input" id="fullname" type="text" name="fullname" />
                                </div>
                                <ErrorMessage name="fullname" className="errormsg" component="p" />
                            </div>
                            <div className="field">
                                <div className="label">
                                  <label htmlFor="phone">Phone:</label>
                                </div>
                                <div className="control">
                                    <Field className="input" id="phone" type="tel" name="phone" />
                                </div>
                                <ErrorMessage name="phone" className="errormsg" component="p" />
                            </div>
                            <div className="field">
                              <div className="label">What are you interested in?</div>
                              <div className="control">
                                <div className={["select", "ismultiple"].join(" ")}>
                                  <Field
                                  component="select"
                                  id="inquiry"
                                  name="inquiryType"
                                  multiple={true}
                                >
                                    <option value="DNA Services">DNA Services (Paternity, Forensics, Species ID)</option>
                                    <option value="Products">Products</option>
                                    <option value="Expert Witness">Expert Witness (Case Review/DNA Expert)</option>
                                    <option value="Other">Other</option>
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                                <div className="label">
                                  <label htmlFor="resellerCo">Reseller Company:</label>
                                </div>
                                <div className="control">
                                    <Field className="input" id="resellerCo" type="text" name="resellerCompany" placeholder="For resellers only" />
                                </div>
                                <ErrorMessage name="resellerCompany" className="errormsg" component="p" />
                            </div>
                            <div className="field">
                                <div className="label">
                                  <label htmlFor="resellerLo">Reseller Location:</label>
                                </div>
                                <div className="control">
                                    <Field className="input" id="resellerLo" type="text" name="resellerLocation" placeholder="For resellers only" />
                                </div>
                                <ErrorMessage name="resellerLocation" className="errormsg" component="p" />
                            </div>
                            <div className="field">
                              <div className="label">
                                <label htmlFor="message">Message:</label>
                              </div>
                              <div className="control">
                                <Field id="message" name="message" component="textarea" className="textarea" />
                              </div>
                              <ErrorMessage name="message" className="errormsg" component="p" />
                            </div>
                            <input type="hidden" name="_cc" value={cc} />
                            <div className="field">
                              <div className="control">
                                <button type="submit" disabled={isSubmitting} className={["formbtn", "blue"].join(' ')}>
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        {serverState && (
                            <p className={!serverState.ok ? "errormsg" : ""} style={{paddingRight: "2rem", paddingLeft: "2rem", textAlign: "center"}}>
                            {serverState.msg}
                            </p>
                        )}
                        </Form>
                    )}
                  </Formik>
                </div>
                <button className={["gen-button", "white"].join(" ")} onClick={e => setExpand(!expand)}>{expand ? "Shrink Form" : "Expand Form"}</button>
                </div>
                <div className={["col", "info"].join(' ')}>
                  <FormInfo />
                 
                </div>
              </div>
       </section>
     );
}