import * as React from 'react'
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import GenForm from '../components/gen-contact-form'
import Container from '../components/container'

export default function HairAnalysis() {
    const pageTitle = "Hair Analysis"
    const pageDescription = "Hair Analysis. Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide."
    const content = {
            title: "Hair Analysis",
            updated: "Aug 04 2022",
        }
    return (
        <Layout>
            <SEO title={pageTitle} description={pageDescription} />
            <section>
                <Container>
                    <div className='hair-analysis-header'>
                        <div>
                            <Header headerText={content.title} />
                        </div>
                        <div>
                            <p>Updated: {content.updated}</p>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="hair-analysis-section">
                <Container>
                    <p>Our cost-effective screening method quickly and efficiently determines the evidence with the highest likelihood of providing sufficient DNA genetic identity information needed to generate a full or partial DNA profile.</p>
                    <p>Watch the video below for a quick overview of our screening method.</p>
                </Container>
            </section>
            <section>
                <iframe className="video" src="https://www.youtube.com/embed/SBQR5pIYj8A" title="YouTube video player"></iframe>
            </section>
            <section className="hair-analysis-section">
                <Container>
                    <Header headerText="Why Hair Analysis" headingTag="h2" classTag="h2 center-text" />
                    <div className="center-text">
                        <p>Hair screening using Flourescent Nuclear DNA Staining is cost-effective and efficient.</p>
                        <p>A full or partial DNA profile can be generated if sufficient DNA genetic identity is present.</p>
                        <p>Our lab also provides other forensic, expert witness, and case review services.</p>
                    </div>
                </Container>
            </section>
            <section className='hair-analysis-img-section'>
                <div className="grid-hair-analysis-img ">
                    <StaticImage src="../images/misc/hair-analysis-image-microscope-1_opt.jpg" alt="Hair Analysis DAPI Image" layout="fixed" width={200} imgStyle={{borderRadius: "100%"}} />
                    <StaticImage src="../images/misc/hair-analysis-image-microscope-2_opt.jpg" alt="Hair Analysis DAPI Image" layout="fixed" width={200} imgStyle={{borderRadius: "100%"}} />
                    <StaticImage src="../images/misc/hair-analysis-image-microscope-3_opt.jpg" alt="Hair Analysis DAPI Image" layout="fixed" width={200} imgStyle={{borderRadius: "100%"}} />
                    <StaticImage src="../images/misc/hair-analysis-image-microscope-4_opt.jpg" alt="Hair Analysis DAPI Image" layout="fixed" width={200} imgStyle={{borderRadius: "100%"}} />
                 </div>
            </section>
            <section>
                <iframe className="video" src="https://www.youtube.com/embed/G0NN-Up2QMM" title="YouTube video player"></iframe>
            </section>
            <section className="hair-analysis-section">
                <Container>
                    <div className='center-text'>
                        <p><strong>For more information about our services and pricing fill out the form below.</strong></p>    
                    </div>
                </Container> 
            </section>
            <GenForm />
        </Layout>
    )
}